import styled, { keyframes } from 'styled-components'

const spin = keyframes`
	0% {
	transform: rotate(0deg);
	}
	100% {
	transform: rotate(360deg);
	}
`

export const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 48px 0;
`

export const Spinner = styled.div`
	border: 8px solid transparent;
	border-top: 8px solid gray;
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: ${spin} 1s linear infinite;
`
