import React from 'react'
import BackButton from '../../../components/Button/Back'
import Container from '../../../components/Container'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import Column from '../../../components/Column'
import { downloadRequest } from '../../../global/Api'
import { saveAs } from 'file-saver'
import videos from '../Class/videos'
import { useDomainConfig } from '../../../global/Domain'

export default function Donwload(){

	const domainConfigs = useDomainConfig()

	async function downloadAutoBot(){

		const response = await downloadRequest('internal/asset/automaticBot.zip', {}, 'false')

		saveAs(response, 'Bot Automatico.zip')

	}

	const classes = videos.filter(video => !domainConfigs.visibleElements.classes.titles.includes(video.title)).map((video, index: number) => (
		<Button
			redirect
			onclick={`/aula/${domainConfigs.link.classes[index]}`}
		>
			{video.title}
		</Button>
	))

	return (
		<>
			<BackButton />
			<Container>
				<Column>
					<Button download onclick={() => downloadAutoBot()}>
						Clique aqui para baixar
					</Button>
					<Button onclick={() => window.open(domainConfigs.link.vpsPartner)}>
						Assine um servidor com o Bot instalado
					</Button>

					<Text textOption='linnedSubtitle'>
						Aulas
					</Text>
					{classes}
				</Column>
			</Container>
		</>
	)

}
