import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

interface textProps {
	haveMargin?: boolean
	fullWidth?: boolean
	centerAlign?: boolean
}

export const TitleText = styled.h1<textProps>`
    font-size: ${styleguide.fontSize.title};
	margin: ${({haveMargin}) => haveMargin ? styleguide.margin.title : 0};
	width: ${({fullWidth}) => fullWidth ? '100%': 'initial'};
	text-align: ${({centerAlign}) => centerAlign ? 'center' : 'left'};
`

export const SubtitleText = styled.h2<textProps>`
    font-size: ${styleguide.fontSize.subtitle};
	margin: ${({haveMargin}) => haveMargin ? styleguide.margin.subtitle : 0};
	width: ${({fullWidth}) => fullWidth ? '100%': 'initial'};
	text-align: ${({centerAlign}) => centerAlign ? 'center' : 'left'};
`

export const ParagraphText = styled.p<textProps>`
    font-size: ${styleguide.fontSize.paragraph};
	margin: ${({haveMargin}) => haveMargin ? styleguide.margin.paragraph : 0};
	width: ${({fullWidth}) => fullWidth ? '100%': '100px'};
	text-align: ${({centerAlign}) => centerAlign ? 'center' : 'left'};
`
