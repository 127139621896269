import React, { useEffect, useRef, useState } from 'react'
import Recaptcha from 'react-google-recaptcha'

import Link from '../../components/Link'
import Text from '../../components/Text'
import Form from '../../components/Form'
import Input from '../../components/Input/Text'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import Popup from '../../components/Popup'
import ApiRequest, { Response } from '../../global/Api'
import { useDomainConfig } from '../../global/Domain'

interface Register {
	name: string,
	email: string,
	phone: string,
	password: string,
	confirmPassword: string,
}

export default function Register(){

	const recaptchaRef = useRef<Recaptcha>(null)

	const navigate = useNavigate()

	const domainConfigs = useDomainConfig()

	useEffect(() => {

		const isLogged = localStorage.getItem('jwtUserData')

		if(isLogged) navigate('/user')

	}, [])

	const user = useRef({} as Register)

	const [popupResponse, setPopupResponse] = useState({} as Response)

	async function register(){

		const captchaValue = recaptchaRef.current?.getValue()

		const affiliate = { code: location.href.split('/register')[1].replace('/', '') }
		if(domainConfigs.link.suitpayAffiliation) affiliate.code = domainConfigs.link.suitpayAffiliation

		const response = await ApiRequest('user/register', { ...user.current, affiliate, captchaValue })

		setPopupResponse(response)

		if([200].includes(response.status)){

			localStorage.setItem('jwtUserData', response.data.token)

			navigate('/user')
			navigate('/user')

		}

	}

	return (
		<>
			<Text haveMargin textOption='title'>
                Crie sua conta com 1 dia gratuito de uso!
			</Text>
			<Form>
				<Input placeholder='José de Alencar Viana' onChange={(event: any) => user.current.name = event.target.value}>
                    Digite seu nome completo
				</Input>
				<Input type='email' onChange={(event: any) => user.current.email = event.target.value}>
                    Digite seu Email
				</Input>
				<Input placeholder='+55 (19) 99123-4567' onChange={(event: any) => user.current.phone = event.target.value}>
                    Telefone
				</Input>
				<Input type='password' onChange={(event: any) => user.current.password = event.target.value}>
                    Crie uma senha
				</Input>
				<Input type='password' onChange={(event: any) => user.current.confirmPassword = event.target.value}>
                    Confirme sua senha
				</Input>
				<Recaptcha ref={recaptchaRef} id={'recaptcha'} sitekey={'6LeEQw4qAAAAAL1NDuk4K1IwxAlWVBYXBo4sWHDK'} />
				<Button onclick={register}>
					Crie sua conta
				</Button>
			</Form>

			<p>
				Já tem uma conta?
				<Link to='/'>
					Faça seu login
				</Link>
			</p>
			<p>
				Ao se cadastrar você concorda com os
				<Link to='/terms'>
					Termos
				</Link>
			</p>

			<Popup data={{...popupResponse}}  />
		</>
	)

}
