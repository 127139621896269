import React, { useEffect } from 'react'
import { InputText, InputEmail, InputPassword, InputNumber } from './styles'
import Column from '../Column'
import Row from '../Row'
import Text from '../Text'

export interface InputChange {
	target: {
		value: string
	}
}

export default function Input({
	children = '',
	type = 'text',
	placeholder = '',
	defaultValue = '',
	bloqued = false,
	isRow = false,
	small = false,
	extrasmall = false,
	onChange,
	onMount = () => { null }
} : {
	children?: any
	type?: string
	placeholder?: string
	defaultValue?: string
	bloqued?: boolean
	isRow?: boolean
	small?: boolean
	extrasmall?: boolean
	onChange: any
	onMount?: any
}){

	useEffect(() => { onMount() }, [])

	return isRow ? (
		<Row>
			{children && <Text>
				{ children }
			</Text>}
			{ renderSwitch(onChange, type, placeholder, defaultValue, small, extrasmall, bloqued) }
		</Row>
	) : (
		<Column small={extrasmall}>
			{ children }
			{ renderSwitch(onChange, type, placeholder, defaultValue, small, extrasmall, bloqued) }
		</Column>
	)

}

function renderSwitch(
	onChange: any,
	type: string,
	placeholder: string,
	defaultValue: string,
	small: boolean,
	extrasmall: boolean,
	bloqued: boolean,
){

	switch (type){

	case 'number':
		return <InputNumber disabled={bloqued} min={0} small={small} defaultValue={defaultValue} onChange={onChange} placeholder={placeholder || '10'} />

	case 'email':
		return <InputEmail disabled={bloqued} small={small} defaultValue={defaultValue} onChange={onChange} placeholder={placeholder || 'exemplo@gmail.com'} />

	case 'password':
		return <InputPassword disabled={bloqued} small={small} defaultValue={defaultValue} onChange={onChange} placeholder={placeholder || '********'} />

	default:
		return <InputText disabled={bloqued} small={small} defaultValue={defaultValue} onChange={onChange} placeholder={placeholder} />

	}

}
