import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

const StyledIframe = styled.iframe`
	min-width: 40px;
	width: 60vw;
	min-height: 300px;
	height: 70vh;
`

export default StyledIframe
