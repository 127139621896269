import React, { useEffect, useRef, useState } from 'react'
import BackButton from '../../../../../components/Button/Back'
import Input from '../../../../../components/Input/Text'
import Button from '../../../../../components/Button'
import Loading from '../../../../../components/Loading'
import ApiRequest, { Response } from '../../../../../global/Api'
import Popup from '../../../../../components/Popup'
import Container from '../../../../../components/Container'

export default function ConfigGlobalAffiliate(){

	const [popupResponse, setPopupResponse] = useState({} as Response)

	const [loadedData, setLoadedData] = useState(false)
	const updatedData = useRef({
		fee: {
			revshare: 0
		}
	})

	useEffect(() => {

		getGlobalConfigs()

	}, [])

	async function getGlobalConfigs(){

		const response = await ApiRequest('configs/global/category/get/affiliate', {}, 'admin')

		if(response.status == 400) return setPopupResponse(response)
		console.log(response.data.properties)
		updatedData.current = response.data.properties
		setLoadedData(true)

	}

	async function updateGlobalConfigs(){

		const response = await ApiRequest('configs/global/category/update', {
			category: 'affiliate',
			updated: updatedData.current
		}, 'admin')

		setPopupResponse(response)

	}

	function formComponent(){

		return (
			loadedData
				? <>
					<Input
						type='number'
						defaultValue={updatedData.current.fee.revshare?.toString()}
						onChange={(event: any) => updatedData.current.fee.revshare = parseInt(event.target.value)}
					>
						Repasse (%)
					</Input>
					<Button
						toedit
						onclick={() => updateGlobalConfigs()}
					>
						Editar
					</Button>
				</>
				: <Loading />
		)

	}

	return (
		<>
			<BackButton />
			<Container>
				Configuração de Afiliados
			</Container>

			<Container>
				{formComponent()}
			</Container>

			<Popup data={popupResponse} />
		</>
	)

}
