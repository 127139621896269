import React, { useEffect, useState } from 'react'
import { ContainerRadio, InputRadio, InputSelect } from './styles'
import Column from '../Column'
import Row from '../Row'

interface option {
	title: string
	id?: string
}

export default function SelectInput({
	optionsArray,
	selectedValue = '#0',
	isRow = false,
	small = false,
	onChange = (value: string) => { null },
	onMount = () => { null }
} : {
	optionsArray: any[]
	selectedValue?: string
	isRow?: boolean
	small?: boolean
	onChange?: any
	onMount?: any
}){

	useEffect(() => { onMount() }, [])

	// const handleChange = () => onChange()

	const options = optionsArray.map((opt: option, i: number) => (
		<option
			key={i}
			selected={selectedValue === opt.id}
			value={opt.id}
		>
			{opt.title}
		</option>
	))

	return isRow ? (
		<Row>
			<InputSelect
				onChange={(event) => onChange(event.target.value)}
				small={small}
			>
				{options}
			</InputSelect>
		</Row>
	) : (
		<Column leftAlign={true}>
			<InputSelect
				onChange={(event) => onChange(event.target.value)}
				small={small}
			>
				{options}
			</InputSelect>
		</Column>
	)

}
