import { Route, Routes, useLocation } from 'react-router-dom'
import { verifyLogin } from './global/Redirect'
import { useEffect, useRef, useState } from 'react'
import { decodeToken, updateToken } from './global/Token'

import Login from './pages/Login'
import Register from './pages/Register'
import NotFound from './pages/NotFound'
import Home from './pages/Client/Home'
import Account from './pages/Client/Account'
import Download from './pages/Client/Download'

import AdminHome from './pages/Admin/Home'
import ConfigChatBot from './pages/Admin/Configs/ChatBot'
import ConfigEmitBet from './pages/Admin/Configs/EmitBet'
import ConfigUsers from './pages/Admin/Configs/Users'
import ConfigAfiliates from './pages/Admin/Configs/Afiliates'
import Plans from './pages/Client/Plan'
import Class from './pages/Client/Class'
import Terms from './pages/Terms'
import ConfigUser from './pages/Admin/Configs/User'
import ConfigGlobal from './pages/Admin/Configs/Global'
import ConfigGlobalAffiliate from './pages/Admin/Configs/Global/Affiliate'
import Reports from './pages/Client/Reports'
import ConfigReports from './pages/Admin/Configs/Reports'
import AboutBet365 from './pages/About/Bet365'

export default function AppRoutes(){

	const [logged, setLogged] = useState(verifyLogin())
	const [loggedAsAdmin, setLoggedAsAdmin] = useState(verifyLogin(true))

	const decodedTokenTimestamp = useRef(0)
	const location = useLocation()

	useEffect(() => {

		setLogged(verifyLogin())
		setLoggedAsAdmin(verifyLogin(true))

		if(verifyLogin()) setLogged(verifyLogin())
		if(verifyLogin(true)) setLogged(verifyLogin(true))

		console.log({ logged, loggedAsAdmin })

	}, [])

	useEffect(() => {

		if(
			decodedTokenTimestamp.current == 0
			|| decodedTokenTimestamp.current + (1000 * 60 * 60) < Date.now()
		) refreshToken()

	}, [location])

	async function refreshToken(){

		console.log('Refresh Token.')

		decodedTokenTimestamp.current = (await decodeToken()).data.timestamp

		if(decodedTokenTimestamp.current != 0) updateToken()

	}

	return (
		<Routes>

			<Route path='/' element={<Login />} />
			<Route path='/register' element={<Register />} />
			<Route path='/register/:code' element={<Register />} />

			{
				logged && (
					<>
						<Route path='/user' element={<Home />} />
						<Route path='/user/account' element={<Account />} />
						<Route path='/user/plans' element={<Plans />} />
						<Route path='/aula/:class' element={<Class />} />
					</>
				)
			}

			{
				loggedAsAdmin && (
					<>
						<Route path='/admin' element={<AdminHome />} />

						<Route path='/admin/configs/global' element={<ConfigGlobal />} />
						<Route path='/admin/configs/global/affiliate' element={<ConfigGlobalAffiliate />} />

						<Route path='/admin/configs/chatbot' element={<ConfigChatBot />} />
						<Route path='/admin/configs/emitbet' element={<ConfigEmitBet />} />
						<Route path='/admin/configs/afiliates' element={<ConfigAfiliates />} />
						<Route path='/admin/configs/reports' element={<ConfigReports />} />

						<Route path='/admin/configs/users' element={<ConfigUsers />} />
						<Route path='/admin/configs/users/:id' element={<ConfigUser />} />
					</>
				)
			}

			<Route path='/terms' element={<Terms />} />
			<Route path='/download' element={<Download />} />
			<Route path='/reports' element={<Reports />} />
			<Route path='/about/bet365' element={<AboutBet365 />} />

			<Route path='*' element={<NotFound />} />
		</Routes>
	)

}
