import React, { useEffect, useState } from 'react'
import StyledPopup from './styles'

interface Response {
	data: {
		msg: string,
		status: number,
		data?: any
	}
}

export default function Popup(data: Response){

	const [isVisible, setIsVisible] = useState(false)

	// console.log(data.data.msg, 'message', isVisible)

	useEffect(() => {

		if(data?.data?.msg !== undefined){

			setIsVisible(true)

			const timer = setTimeout(() => setIsVisible(false), 2000)
			return () => clearTimeout(timer)

		}

	}, [data.data])

	return (
		<StyledPopup
			isError={data?.data?.status == 200 ? false : true}
			visible={isVisible}
			msg={data.data.msg}
		>
			{data.data.msg}
		</StyledPopup>
	)

}
