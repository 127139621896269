import styled from 'styled-components'
import { styleguide } from '../../global/Theme'

interface styles {
	withoutMargin?: boolean
	fullWidth?: boolean
}

const StyledContainer = styled.div<styles>`
	background-color: ${styleguide.backgroundColor.container};
	border-radius: ${styleguide.borderRadius.container};
	padding: ${styleguide.padding.container};
	margin: ${({withoutMargin}) => withoutMargin ? '0' : styleguide.margin.container};

	width: ${({fullWidth}) => fullWidth ? `calc(100% - (2 * ${styleguide.padding.container}))` : 'auto'};
	/* display: flex;
	flex-direction: column; */
`

export default StyledContainer
