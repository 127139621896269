import React from 'react'
import Text from '../../../../components/Text'
import Button from '../../../../components/Button'
import BackButton from '../../../../components/Button/Back'
import Container from '../../../../components/Container/styles'

export default function ConfigEmitBet(){

	return (
		<>
			<BackButton />
			<Container>
				<Text textOption='title'>
				Envie a aposta manual
				</Text>
			</Container>
			<Button onclick={() => console.log(1)}>
				Enviar Sinal
			</Button>
		</>
	)

}
