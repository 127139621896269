import React from 'react'
import VerticalContainer from './styles'

export default function Column({
	children,
	small,
	leftAlign,
} : {
	children: any,
	small?: boolean,
	leftAlign?: boolean,
}){

	return (
		<VerticalContainer
			leftAlign={leftAlign}
			small={small}
		>
			{children}
		</VerticalContainer>
	)

}
