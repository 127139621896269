import React from 'react'
import BackButton from '../../../components/Button/Back'
import Container from '../../../components/Container/styles'
import Iframe from '../../../components/Iframe'
import videos from './videos'
import Text from '../../../components/Text'
import { useDomainConfig } from '../../../global/Domain'

export default function Class(){

	const domainConfigs = useDomainConfig()

	const url = location.href.split('/aula/')[1]

	const index = domainConfigs.link.classes.findIndex((link: string) => link == url)

	return (
		<>
			<BackButton />
			<Container>
				<Text textOption='title' haveMargin>
					{videos[index].title}
				</Text>
				<Iframe
					title={videos[index].title}
					src={domainConfigs.link.classes[index]}
				/>
			</Container>
		</>
	)

}
