import React from 'react'
import ApiRequest, { downloadRequest } from '../../../global/Api'
import { saveAs } from 'file-saver'

import Container from '../../../components/Container/styles'
import Text from '../../../components/Text'
import Button from '../../../components/Button'
import Column from '../../../components/Column'
import BackButton from '../../../components/Button/Back'

export default function AdminHome(){

	async function downloadGenegateBot(){

		const response = await downloadRequest('internal/asset/generatorBot.zip', { password: 'codeso2764SAVEBUIR' }, 'false')

		saveAs(response, 'Bot Gerador.zip')

	}

	async function downloadGenegateBot2(){

		const response = await downloadRequest('internal/asset/generatorBot2.zip', { password: 'codeso2764SAVEBUIR' }, 'false')

		saveAs(response, 'Bot Gerador Rebel.zip')

	}

	return (
		<>
			<BackButton />
			<Container>
				<Text textOption='title'>
					Verifique as opções:
				</Text>
			</Container>
			<Container>
				<Column>
					<Button redirect onclick={'/admin/configs/global'}>
						Configurações Gerais
					</Button>

					<Button redirect onclick={'/admin/configs/users'}>
						Gerenciar Usuários
					</Button>

					<Button redirect onclick={'/admin/configs/reports'}>
						Gerenciar Estatísticas
					</Button>

					<Button download onclick={() => downloadGenegateBot()}>
						Baixar Bot Gerador
					</Button>
					<Button download onclick={() => downloadGenegateBot2()}>
						Baixar Bot Gerador Rebel
					</Button>
				</Column>
			</Container>
		</>
	)

}
