import React from 'react'
import { useNavigate } from 'react-router-dom'

export default function(route: string){

	const navigation = useNavigate()

	navigation(route)

}

export function verifyLogin(isAdmin?: boolean): boolean {

	const token = isAdmin ? 'jwtAdminData' : 'jwtUserData'

	const isLogged = localStorage.getItem(token)
	// console.log({isLogged})
	return !!isLogged

}
