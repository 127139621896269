import React from 'react'
import { DefaultForm } from './styles'

export default function Form(props: any){

	return (
		<DefaultForm>
			{props.children}
		</DefaultForm>
	)

}
