import React, { useEffect, useState } from 'react'
import Loading from '../../../components/Loading'
import Circle from '../../../components/Icons/Circle'
import Chart from 'chart.js/auto'
import { CategoryScale } from 'chart.js'
import { Line } from 'react-chartjs-2'
import './index.css'
import { styleguide } from '../../../global/Theme'

Chart.register(CategoryScale)

import mobileMarcielBg1Image from '../../../assets/img/mobile_marcielBg_365_1.png'
import mobileMarcielBg2Image from '../../../assets/img/mobile_marcielBg_365_2.png'
import mobileMarcielBg3Image from '../../../assets/img/mobile_marcielBg_365_3.png'
import desktoparcielBg1Image from '../../../assets/img/desktop_marcielBg_365_1.png'
import desktoparcielBg2Image from '../../../assets/img/desktop_marcielBg_365_2.png'
import desktoparcielBg3Image from '../../../assets/img/desktop_marcielBg_365_3.png'

import print365Prejuizo from '../../../assets/img/print_bet365_prejuizo.png'
import printClienteSucesso365_1 from '../../../assets/img/printCel1.png'
import printClienteSucesso365_2 from '../../../assets/img/printCel2.png'
import printClienteSucesso365_3 from '../../../assets/img/printCel3.png'
import printClienteSucesso365_4 from '../../../assets/img/printCel4.png'
import printClienteSucesso365_5 from '../../../assets/img/printCel5.png'

import marcielImage from '../../../assets/img/marciel1.png'
import logoBuscagreen from '../../../assets/img/logoBuscagreen.png'

import ApiRequest from '../../../global/Api'
import { useDomainConfig } from '../../../global/Domain'
import Button from '../../../components/Button'

const styles: { [
	key in
	'baseText' |
	'smallText' |
	'bigText' |
	'adButton' |
	'background'
]: React.CSSProperties } = {
	baseText: {
		fontFamily: 'Poppins',
		fontSize: '20px',
		fontWeight: '600',
		width: '80vw',
		padding: '10px 0 10px 10vw',
	},
	smallText: {
		fontSize: '14px',
		fontWeight: '400',
	},
	bigText: {
		fontSize: '26px',
		fontWeight: '800',
	},
	adButton: {
		backgroundColor: '#24ab77',
		padding: '8px 24px',
		border: 'none',
		borderRadius: '15px',
		margin: '24px 0 12px 0',

		cursor: 'pointer',
	},
	background: {
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		width: '100vw',
	}
}

// const desktopStyles = styles

// desktopStyles.

export default function AboutBet365(){

	const domainConfig = useDomainConfig()

	const [visualConfigs, setVisualConfigs] = useState({
		background1: mobileMarcielBg1Image,
		background2: mobileMarcielBg2Image,
		background3: mobileMarcielBg3Image,
		isDesktop: false,
		cssExpecfication: {
			p1: {}, pPrejuizo: {},
			button1: {}, button2: {},
			img1: {},
			divPrejuizo: {}
		}
	})

	const [reportsData, setReportsData] = useState({
		timeAbout: '',
		totalBets: 0,
		totalProfit: 0,
		averageOdds: 0,
		ROI: 0,
		averageCLV: 0,
		averagePercentage: 0,
		betsOnDate: [] as any[],
		graphics: {
			totalCLVProfits: [] as number[],
			totalProfits: [] as number[]
		}
	})

	const [timeDate, setTimeDate] = useState('month')

	const generalDataStyle: React.CSSProperties = {
		margin: '8px',
		padding: '12px',
		width: '35%',
		border: '1px solid white',

		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	}

	const textTime: { [key: string]: string } = {
		forever: 'Lucros dos últimos 12 meses',
		month: 'Lucros dos últimos 30 dias'
	}

	const textTimeTitle: { [key: string]: string } = {
		forever: 'Referente aos últimos 12 meses',
		month: 'Referente aos últimos 30 dias'
	}

	useEffect(() => {

		getReportsData()

		document.body.classList.add('margin0')

		const resizeEvent = () => {

			const width = document.querySelector('body')?.clientWidth

			if(width && width >= 580) setVisualConfigs({
				background1: desktoparcielBg1Image,
				background2: desktoparcielBg2Image,
				background3: desktoparcielBg3Image,
				isDesktop: true,
				cssExpecfication: {
					p1: {
						width: '30vw',
						marginLeft: '10vw',
						textAlign: 'left',
					},
					pPrejuizo: {
						width: 'calc(100vw - 500px - 30vw)',
						paddingLeft: '0',
						paddingTop: '24px',
						textAlign: 'left',
					},
					button1: {
						width: '30vw',
						marginLeft: '20vw',
						display: 'block',
					},
					button2: {
						width: '30vw',
						marginLeft: '35vw',
						display: 'block',
					},
					img1: {
						width: '15vw',
						marginLeft: '20vw',
					},
					divPrejuizo: {
						display: 'flex',
						flexDirection: 'row-reverse',
						width: '80vw',
						marginLeft: '10vw'
					}
				}
			})

		}

		window.addEventListener('resize', resizeEvent, true)
		resizeEvent()

		return () => {

			document.body.classList.remove('margin0')

			window.removeEventListener('resize', resizeEvent, true)

		}

	}, [])

	useEffect(() => {

		setReportsData({
			timeAbout: '',
			totalBets: 0,
			totalProfit: 0,
			averageOdds: 0,
			ROI: 0,
			averageCLV: 0,
			averagePercentage: 0,
			betsOnDate: [] as any[],
			graphics: {
				totalCLVProfits: [] as number[],
				totalProfits: [] as number[]
			}
		})

		getReportsData(timeDate)

	}, [timeDate])

	async function getReportsData(timeData = ''){
		// 'forever'

		if(timeData) setTimeDate(timeData)

		const response = await ApiRequest('/user/get/reports/bet365', { timeData })

		if(response.status != 400){

			const totalProfits: number[] = []
			const totalCLVProfits: number[] = []

			const profits = Object.values(response.data.betsOnDate).map((el: any) => parseFloat(el.profit))
			const CLVProfits = Object.values(response.data.betsOnDate).map((el: any) => parseFloat(el.CLVProfit))

			// console.log({ profits })

			for(let i = 0; i < profits.length; i++){

				totalProfits.push(
					(totalProfits[i - 1] || 0) + profits[i]
				)

				totalCLVProfits.push(
					(totalCLVProfits[i - 1] || 0) + CLVProfits[i]
				)

			}

			// console.log({ totalProfits })

			setReportsData({
				...response.data,
				graphics: {
					totalProfits,
					totalCLVProfits
				}
			})

		}

	}

	function handleBuyLink(){

		window.location.href = 'https://hub.la/g/i7nq8hMviqLrMdkrZuTN#2'

	}

	const resultsLengthIndex = parseInt((reportsData.graphics.totalProfits.length / 10).toFixed(0))

	return (
		<>
			<div style={{
				...styles.background,
				backgroundImage: `url(${visualConfigs.background1})`,
				minHeight: '100vh'
			}}>
				<div style={{ height: '45vh' }}></div>
				<img
					src={logoBuscagreen}
					alt=''
					style={{
						width: '60vw',
						maxWidth: '400px',
						display: 'block',
						margin: '0 auto',
						...visualConfigs.cssExpecfication.img1,
					}}
				/>
				<p style={{
					...styles.baseText,
					...visualConfigs.cssExpecfication.p1
				}}>
					🤖 Descubra o Segredo dos Apostadores Profissionais com Nosso Robô de Apostas de Valor! 🤖
					<br /><br />
					🎯 Você está cansado de perder apostas por falta de informação? Chegou a hora de virar o jogo!
				</p>
				<p style={{
					...styles.baseText,
					...styles.smallText,
					...visualConfigs.cssExpecfication.p1
				}}>
				É com essas estratégias que a BET365 pela primeira vez na história registrou um prejuízo ANUAL nas apostas esportivas.
				</p>
				<button style={{
					...styles.baseText,
					...styles.adButton,
					...visualConfigs.cssExpecfication.button1,
				}}
				onClick={handleBuyLink}
				>
					Quero receber meu acesso
				</button>
				<div style={{ height: '96px' }}></div>
			</div>
			<div style={{
				...styles.background,
				backgroundImage: `url(${visualConfigs.background2})`,
				// backgroundRepeat: 'no-repeat',
				// backgroundSize: 'cover',
				width: '100vw',
			}}>
				<div style={{ height: '24px' }}></div>
				<p style={{
					...styles.baseText,
				}}>
				Nosso Robô de Apostas de Valor analisa milhares de dados em tempo real e identifica as melhores oportunidades para você apostar com confiança. 📊💡
				</p>
				<div style={{
					...visualConfigs.cssExpecfication.divPrejuizo
				}}>
					<p style={{
						...styles.baseText,
						...styles.smallText,
						...visualConfigs.cssExpecfication.pPrejuizo
					}}>
					💰 Por que confiar no nosso robô?
						<br />
					✅ Precisão: Identifica as odds com valor real para maximizar seus lucros!
						<br />
					✅ Velocidade: Faz análises em segundos, garantindo que você não perca nenhuma oportunidade!
						<br />
					✅ Automatização: Deixe o robô trabalhar por você enquanto aproveita seus ganhos!
						<br /><br />
					🔥 Oferta Exclusiva: Experimente o Robô de Apostas de Valor por 7 dias grátis! 🔥 se não gostar pode pedir o reembolsado.
						<br /><br />
					🚀 Não perca tempo – Comece agora e veja a diferença em seus resultados! 🚀
						<br /><br />
					💸 Aposte com inteligência, aposte com valor! 💸
					</p>
					<img
						src={print365Prejuizo}
						alt='Bet 365 Prejuizo de 61 milhões'
						style={{
							width: '80vw',
							maxWidth: '500px',
							display: 'block',
							margin: '24px auto 0 auto',
							border: '15px solid rgb(53,53,53)',
							borderRadius: '15px'
						}}
					/>
				</div>
				<div style={{ height: '24px' }}></div>
				<p
					style={{
						...styles.baseText,
						...styles.bigText
					}}
				>
				Dados Gerais Bet365
				</p>
				{
					reportsData.timeAbout ? (
						<>
							<br />
							<p
								style={{
									...styles.baseText,
								}}
							>
								{textTimeTitle[timeDate]}
							</p>
							<br />
							<div style={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'center',
							}}>
								<div style={{
									...styles.baseText,
									...styles.smallText,
									...generalDataStyle,
								}}>
								Banca Inicial:
									<br />
								R$10 000,00
								</div>
								<div style={{
									...styles.baseText,
									...styles.smallText,
									...generalDataStyle,
								}}>
								Stake:
									<br />
								R$100,00 (1%)
								</div>
								<div style={{
									...styles.baseText,
									...styles.smallText,
									...generalDataStyle,
								}}>
								Lucro total:
									<br />
									<span style={{
										color: reportsData.totalProfit >= 0 ? styleguide.backgroundColor.success : styleguide.backgroundColor.error,
										fontWeight: 700
									}}>
									R${reportsData.totalProfit.toFixed(2)}
									</span>
								</div>
								<div style={{
									...styles.baseText,
									...styles.smallText,
									...generalDataStyle,
								}}>
								Qtd apostas:
									<br />
									{reportsData.totalBets}
								</div>
								<div style={{
									...styles.baseText,
									...styles.smallText,
									...generalDataStyle,
								}}>
								Odds médias:
									<br />
									{reportsData.averageOdds.toFixed(2)}
								</div>
								<div style={{
									...styles.baseText,
									...styles.smallText,
									...generalDataStyle,
								}}>
								ROI:
									<br />
									{(reportsData.ROI * 100).toFixed(2)}%
								</div>
								<div style={{
									...styles.baseText,
									...styles.smallText,
									...generalDataStyle,
								}}>
								CLV médio:
									<br />
									{(reportsData.averageCLV * 100).toFixed(2)}%
								</div>
								<div style={{
									...styles.baseText,
									...styles.smallText,
									...generalDataStyle,
								}}>
								Porcentagem média:
									<br />
									{(reportsData.averagePercentage * 100).toFixed(2)}%
								</div>
							</div>
						</>
					) : <Loading />
				}
				{
					reportsData.betsOnDate ? (
						<div
							style={{
								margin: '24px auto',
								width: '80vw',
								maxWidth: '600px',
								height: 'auto',
							}}
						>
							<Line
								data={{
									labels: Object.keys(reportsData.betsOnDate).reverse().filter((el: any, index: number) => index % resultsLengthIndex == 0),
									datasets: [
										{
											label: 'Lucro Total Esperado',
											data: reportsData.graphics.totalCLVProfits.filter((el: any, index: number) => index % resultsLengthIndex == 0).map((el: number) => el.toFixed(2)),
											backgroundColor: [
												'#84b5ff',
											],
											pointBackgroundColor: '#0066ff',
											borderColor: '#0066ff',
											borderWidth: 2,
											tension: 0.4,
										},
										{
											label: 'Lucro Total',
											data: reportsData.graphics.totalProfits.filter((el: any, index: number) => index % resultsLengthIndex == 0).map((el: number) => el.toFixed(2)),
											backgroundColor: [
												'rgb(136, 191, 77)',
											],
											pointBackgroundColor: '#007902',
											borderColor: '#007902',
											borderWidth: 2,
											fill: true,
											tension: 0.4,
										},
									],
								}}
								options={{
									plugins: {
										title: {
											display: true,
											text: `Lucros ${textTime[timeDate]}`
										}
									},
								}}
							/>
						</div>
					) : <Loading />
				}
				<Button onclick={() => setTimeDate('forever')}>
					Ver resultados dos últimos 12 meses
				</Button>
				<Button onclick={() => setTimeDate('month')}>
					Ver resultados dos últimos 30 dias
				</Button>
				<p style={{
					...styles.baseText,
					...styles.bigText,
					margin: '48px 0 48px 0',
				}}>
					<SpanColor2>Veja</SpanColor2> alguns <SpanColor1>resultados</SpanColor1>
				</p>
				<div style={{
					display: 'flex',
					justifyContent: 'space-evenly',
				}}>
					<Carousel images={[
						printClienteSucesso365_1, printClienteSucesso365_2, printClienteSucesso365_3,
						// printClienteSucesso365_4,
						// printClienteSucesso365_5
					]} />
				</div>
				<div style={{ height: '48px' }}></div>
			</div>
			<div style={{
				...styles.background,
				backgroundImage: `url(${visualConfigs.background3})`
			}}>
				<div style={{ height: '24px' }}></div>
				<p style={{
					...styles.baseText,
					...styles.bigText,
				}}>
					Prazer, <SpanColor2>Marciel</SpanColor2> <SpanColor1>Santana</SpanColor1>
				</p>
				<p style={{
					...styles.baseText,
					...styles.smallText
				}}>
					Sou um apaixonado por futebol desde que nasci...
					<br />
					Senore estuve envolvido com esse universo das apostas esportivas, porém só em 2017 que consegui entender que aposta tem tudo a ver com MATEMÁTICA e não com sorte ou time favorito.
					<br /><br />
					Comeei a ser lucrativo no mercado de apostas esportivas só depois que usei matemática nas Odds para extrair bônus, surebet, cashout e valuebet, e hoje estamos com foco principal no bot de Valuebet da Bet365 onde as odds com erro são notificadas em tempo real.
					<br /><br />
					Desde 2017 venho aplicando estratégias exclusivas em minhas apostas, que me permitem ser lucrativo todos os dias e manter meu padrão de vida.
					<br /><br />
					E agora vou te disponibilizar a mesma ferramenta que uso a anos pra ser lucrativo nesse mercado.
				</p>
				<img
					src={marcielImage}
					alt='Marciel'
					style={{
						width: '90vw',
						maxWidth: '500px',
						marginTop: '24px'
					}}
				/>
				<button style={{
					...styles.baseText,
					...styles.adButton,
					...visualConfigs.cssExpecfication.button2,
				}}
				onClick={handleBuyLink}
				>
					Quero receber meu acesso
				</button>
				<hr style={{margin: '30px 0'}} />
				<p style={{padding: '0 0 30px 0'}}>
					Buscagreen {(new Date).getFullYear()} - Todos os direitos reservados
				</p>
			</div>
			<Circle from='instagram' url='https://www.instagram.com/buscagreen_valuebet?igsh=MWJudmVsdHN5aWJvdw%3D%3D&utm_source=qr' />
			<Circle from='whatsapp' url='https://chat.whatsapp.com/KK21whPFXcr0Q4jxQ5NLtR' left />
		</>
	)

}

function SpanColor1(props: any){

	return (
		<span style={{
			fontFamily: 'Poppins',
			color: '#24ab77'
		}}>
			{props.children}
		</span>
	)

}

function SpanColor2(props: any){

	return (
		<span style={{
			fontFamily: 'Poppins',
			color: '#dbc11d'
		}}>
			{props.children}
		</span>
	)

}

interface CarouselProps {
	images: string[]
}

const Carousel: React.FC<CarouselProps> = ({ images }) => {

	const [currentIndex, setCurrentIndex] = useState(0)

	const nextImage = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
	}

	const prevImage = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex === 0 ? images.length - 1 : prevIndex - 1
		)
	}

	useEffect(() => {

		setInterval(nextImage, 10000)

	}, [])

	return (
		<div className="carousel-container">
			<button className="carousel-button prev" onClick={prevImage}>
				{'<'}
			</button>
			<div className="carousel-images">
				{images.map((image, index) => (
					<img
						key={index}
						src={image}
						alt={`Image ${index + 1}`}
						className={`carousel-image ${
							index === currentIndex ? 'active' : ''
						} ${index === (currentIndex - 1 + images.length) % images.length ? 'left' : ''} ${
							index === (currentIndex + 1) % images.length ? 'right' : ''
						}`}
					/>
				))}
			</div>
			<button className="carousel-button next" onClick={nextImage}>
				{'>'}
			</button>
		</div>
	)
}
