import React, { useEffect, useRef, useState } from 'react'
import Text from '../../../../components/Text'
import Button from '../../../../components/Button'
import BackButton from '../../../../components/Button/Back'
import Container from '../../../../components/Container/styles'
import Column from '../../../../components/Column'
import Input from '../../../../components/Input/Text'
import ApiRequest, { Response } from '../../../../global/Api'
import Popup from '../../../../components/Popup'


export default function ConfigAfiliates(){

	interface userMap {
		email: string,
		phone: string,
		password: string,
		planPermissions: Map<string, any>
	}

	const [users, setUsers] = useState([])
	const usersRef = useRef([] as Array<userMap>)

	const [popupResponse, setPopupResponse] = useState({} as Response)

	useEffect(() => { listUsers() }, [])

	async function listUsers(){

		const response = await ApiRequest('admin/show/user', {}, 'admin')

		if(response.status == 200) setUsers(response.data)

	}

	async function updateAffiliate(user: userMap){

		console.log({user})

	}

	function usersRender(){

		return users.map((user: userMap, index: number)  => (
			<Container key={index}>
				<Column>
					<Input
						isRow
						defaultValue={user.email}
						onChange={(event: any) => usersRef.current[index] = { ...user, email: event.target.value }}
						onMount={() => usersRef.current[index] = { ...user }}
					>
						Email:
					</Input>
					<Input
						isRow
						defaultValue={user.phone}
						onChange={(event: any) => usersRef.current[index] = { ...user, phone: event.target.value }}
					>
						Telefone:
					</Input>
					<Input
						isRow
						defaultValue={user.password}
						onChange={(event: any) => usersRef.current[index] = { ...user, password: event.target.value }}
					>
						Senha:
					</Input>
					<Button
						onclick={() => updateAffiliate(usersRef.current[index])}
					>
						Editar
					</Button>
				</Column>
			</Container>
		))

	}

	return (
		<>
			<BackButton />
			<Container>
				<Text textOption='title'>
				Edite seus afiliados
				</Text>
			</Container>
			{
				users.length > 0
					? usersRender()
					: <Container>
						<Text textOption='subtitle'>
					Sem usuários...
						</Text>
					</Container>
			}

			<Popup data={{...popupResponse}} />
		</>
	)

}
