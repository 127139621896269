import React, { useEffect, useRef, useState } from 'react'

import Link from '../../components/Link'
import Text from '../../components/Text'
import Form from '../../components/Form'
import Input, { InputChange } from '../../components/Input/Text'
import Button from '../../components/Button'
import { useNavigate } from 'react-router-dom'
import ApiRequest, { Response } from '../../global/Api'
import Popup from '../../components/Popup'

interface Login {
	email: string
	password: string
}

export default function Login(){

	const navigate = useNavigate()

	useEffect(() => {

		const isLogged = localStorage.getItem('jwtUserData')

		if(isLogged) navigate('/user')

	}, [])

	const user = useRef({} as Login)

	const [popupResponse, setPopupResponse] = useState({} as Response)

	async function login(){

		const { email, password } = user.current

		const response = await ApiRequest('user/login', {
			email, password
		})

		setPopupResponse(response)

		if([200].includes(response.status)){

			// window.addEventListener('storage', () => navigate('/user'))

			localStorage.setItem('jwtUserData', response.data.token)

			response.data.haveAdminPermissions
				? localStorage.setItem('jwtAdminData', response.data.token)
				: localStorage.removeItem('jwtAdminData')

			navigate('/user')
			navigate('/user')

		}

	}

	return (
		<>
			<Text haveMargin textOption='title'>
                Acesse sua conta
			</Text>
			<Form>
				<Input
					type='email'
					onChange={(event: InputChange) => user.current.email = event.target.value}>
                    Digite seu email
				</Input>
				<Input
					type='password'
					onChange={(event: InputChange) => user.current.password = event.target.value}>
                    Digite sua senha
				</Input>
				<Button onclick={login}>
					Acesse sua conta
				</Button>
			</Form>

			<p>
				Não tem conta?
				<Link to='/register'>
					Faça seu cadastro
				</Link>
			</p>

			<Popup data={{...popupResponse}}  />
		</>
	)

}
