import React from 'react'
import { TitleText, SubtitleText, ParagraphText } from './styles'
import Row from '../Row'

type textOptions = 'title' | 'subtitle' | 'paragraph' | 'linnedSubtitle'

interface TextProps {
	children: React.ReactNode
	textOption?: textOptions
	haveMargin?: boolean
	fullWidth?: boolean
	centerAlign?: boolean
}

export default function Text({
	children,
	textOption = 'paragraph',
	haveMargin = false,
	fullWidth = false,
	centerAlign = true
}: TextProps){

	return chooseTextType(children, textOption, haveMargin, fullWidth, centerAlign)

}

function chooseTextType(children: React.ReactNode, textOption: textOptions, haveMargin: boolean, fullWidth: boolean, centerAlign: boolean){

	switch (textOption) {
	case 'title':
		return (
			<TitleText haveMargin={haveMargin} fullWidth={fullWidth} centerAlign={centerAlign}>
				{children}
			</TitleText>
		)
	case 'subtitle':
		return (
			<SubtitleText haveMargin={haveMargin} fullWidth={fullWidth} centerAlign={centerAlign}>
				{children}
			</SubtitleText>
		)

	case 'linnedSubtitle':
		return linnedSubtitle(children, centerAlign)

	default:
		return (
			<ParagraphText haveMargin={haveMargin} fullWidth={fullWidth} centerAlign={centerAlign}>
				{children}
			</ParagraphText>
		)
	}

}

function linnedSubtitle(children: React.ReactNode, centerAlign: boolean){

	return (
		<Row totalWidth>
			<hr style={{width: '-webkit-fill-available', margin: '16px'}}></hr>
			<SubtitleText centerAlign={centerAlign}>
				{children}
			</SubtitleText>
			<hr style={{width: '-webkit-fill-available', margin: '16px'}}></hr>
		</Row>
	)

}
