import React from 'react'
import { useNavigate } from 'react-router-dom'
import StyledHeader from './styles'
import Button from '../Button'
import Text from '../Text'
import { useDomainConfig } from '../../global/Domain'

export default function Header(){

	const domainConfigs = useDomainConfig()

	const navigate = useNavigate()

	function logout(){

		localStorage.removeItem('jwtUserData')
		localStorage.removeItem('jwtAdminData')

		navigate('/')

	}

	return (
		<StyledHeader>
			<Text textOption='title'>
				{domainConfigs.writings.title}
			</Text>
			<Button small onclick={logout}>Sair</Button>
		</StyledHeader>
	)

}
