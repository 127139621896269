import React, { useEffect, useState } from 'react'
import { ContainerRadio, InputRadio } from './styles'
import Column from '../Column'
import Row from '../Row'

interface option {
	name: string
	value?: string
}

export default function RadioInput({
	optionsArray,
	name,
	selectedIndex = 0,
	isRow = false,
	small = false,
	onChange = (index: number) => { null },
	onMount = () => { null }
}: {
	optionsArray: any[]
	name: string
	selectedIndex?: number,
	isRow?: boolean
	small?: boolean
	onChange?: any
	onMount?: any
}){

	useEffect(() => { onMount() }, [])

	const handleChange = (index: number) => onChange(index)

	const options = optionsArray.map((opt: option, i: number) => (
		<ContainerRadio
			key={i}
			checked={i == selectedIndex}
			onClick={() => handleChange(i)}
		>
			<InputRadio
				checked={i == selectedIndex}
				name={name}
				value={opt?.value || i}
				small={small}
			/>
			{opt.name}
		</ContainerRadio>
	))

	return isRow ? (
		<Row>
			{options}
		</Row>
	) : (
		<Column leftAlign={true}>
			{options}
		</Column>
	)

}
