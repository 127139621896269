import React from 'react'
import { StyledBackButton } from './styles'
import { useNavigate } from 'react-router-dom'

export default function BackButton(){

	const navigate = useNavigate()

	return (
		<StyledBackButton small onClick={() => navigate(-1)}>
			{'<- Voltar'}
		</StyledBackButton>
	)

}
