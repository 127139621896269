import React, { createContext } from 'react'

import { BrowserRouter } from 'react-router-dom'

import GlobalStyle from './global/Theme'
import AppRoutes from './Routes'
import { DomainProvider } from './global/Domain'

let defaultPath = ''

export default function App(){

	defaultPath = window.location.origin
	if(defaultPath == 'http://localhost:3000') defaultPath = 'http://localhost:8000'

	return (
		<BrowserRouter>
			<DomainProvider>
				<GlobalStyle />
				<AppRoutes />
			</DomainProvider>
		</BrowserRouter>
	)

}

export { defaultPath }
